import { isNil, map, pipe, sum, prop, clone, assocPath, dissocPath, pick, hasPath } from 'ramda';

import { TABLE_TYPE } from './constants';

const createOrderTableRows = (order, tenantName, supplierName) => {
  return order.differences.map((diff) => {
    const orderItem = order.products.find(({ id }) => id === diff.customerItemId);
    return {
      metadata: {
        index: order.products.indexOf(orderItem),
        order,
        tenantName,
        supplierName,
      },
      date: order.date,
      document: order.source?.document,
      product: diff.product,
      terms: pick(['quantity', 'price', 'discount'], orderItem || { quantity: 0 }),
      differences: pick(['quantity', 'price', 'discount'], diff),
      totalDiff: diff.amount,
    };
  });
};

const hasTables = (selectedRecords, month) => {
  const tables = Object.values(TABLE_TYPE);
  return !!Object.keys(pick([tables], selectedRecords[month])).length;
};

const removeSelected = (month, tableName, data, metadata) => {
  // differences table
  if (metadata?.recordId) {
    const withoutCurrentRecordId = dissocPath([month, tableName, metadata.recordId], data);
    const hasOtherRecords = !!Object.keys(withoutCurrentRecordId[month][tableName]).length;

    if (hasOtherRecords) {
      return withoutCurrentRecordId;
    }
  }

  const withoutCurrentTable = dissocPath([month, tableName], data);

  if (hasTables(withoutCurrentTable, month)) {
    return withoutCurrentTable;
  }

  return dissocPath([month], data);
};

const addSelected = (month, groupDate, tableName, selected, data = {}, metadata) => {
  if (!hasPath([month, 'groupDate'], data)) {
    data = assocPath([month, 'groupDate'], groupDate, data);
  }
  // differences table
  if (metadata?.recordId) {
    return assocPath([month, tableName, metadata.recordId], { selected, metadata }, data);
  }

  // unbilled or missingDocuments
  return assocPath([month, tableName], selected, data);
};

const getMonthKey = (date = new Date()) => {
  return new Date(date).toLocaleString('default', { month: 'long' }).toLowerCase();
};

const fillByDate = (tableKey, dateKey, array, reference) => {
  for (let index = 0; index < array.length; index++) {
    const item = array[index];
    const monthKey = getMonthKey(item[dateKey]);

    if (!reference[monthKey]) {
      reference[monthKey] = {
        key: `${monthKey}-group`,
        groupDate: item[dateKey],
        reports: [],
        unbilled: [],
        missingDocuments: [],
      };
    }

    reference[monthKey][tableKey].push(item);
  }
};
//--------------------------------------- external ------------------------------------------------------

export const isMissingPrice = (orders) => {
  return orders.some((order) => order.products.some((product) => isNil(product.price)));
};

export const sumArrayByKey = (array, key) => {
  return array.length ? pipe(map(prop(key)), sum)(array) : null;
};

export const createDifferenceTableRows = (billing, orders, tenantName, supplierName) => {
  return billing.orderLinks
    .flatMap(({ order: linkedOrder }) => {
      const order = orders.find((o) => o.id === linkedOrder?.id);
      return order?.diffs?.length ? createOrderTableRows(order, tenantName, supplierName) : [];
    })
    .sort((orderA, orderB) => orderB.date - orderA.date);
};

export const isOneMonth = ({ from, to }) => {
  return new Date(from).getMonth() === new Date(to).getMonth();
};

export const createMissingTableRows = (billings) => {
  return billings.flatMap((billing) => {
    return billing.deliveryRefs
      .filter(({ delivery }) => !delivery)
      .map(({ externalRef, date }) => ({
        document: { type: 'deliveryNote', documentNumber: externalRef },
        date,
        billingDate: billing.date,
      }));
  });
};

export const createUnbilledTableRows = (billings, orders, tenantName, supplierName) => {
  const linkedOrders = billings.flatMap((billing) =>
    billing.orderLinks.filter(({ order }) => !!order).map(({ order }) => order.id)
  );
  return orders
    .filter(({ diffs, id }) => diffs?.length && !linkedOrders.includes(id))
    .flatMap((order) => createOrderTableRows(order, tenantName, supplierName));
};

export const groupByMonth = (reports, unbilledOrders, missingDocuments) => {
  let reportsByDate = {};

  fillByDate('reports', 'date', reports, reportsByDate);
  fillByDate('unbilled', 'date', unbilledOrders, reportsByDate);
  fillByDate('missingDocuments', 'billingDate', missingDocuments, reportsByDate);

  return Object.values(reportsByDate);
};

export const modifySelectedRecords = (groupDate, tableName, newSelected, selectedRecords = {}, metadata) => {
  const clonedData = clone(selectedRecords);
  const month = new Date(groupDate).getMonth();
  return newSelected?.length > 0
    ? addSelected(month, groupDate, tableName, newSelected, clonedData, metadata)
    : removeSelected(month, tableName, clonedData, metadata);
};
