<template>
  <div class="order">
    <h3 class="open-issues-table-title">{{ title }}</h3>
    <el-table :data="records" @selection-change="$emit('selection-change', $event)">
      <el-table-column type="selection" class-name="selection-column" />
      <el-table-column v-slot="{ row: { date } }" width="90" :label="$t('openIssuesModal.table.order.label.date')">
        {{ formatDate(date) }}
      </el-table-column>
      <el-table-column v-slot="{ row: { document } }" :label="$t('openIssuesModal.table.order.label.order')">
        {{ $t(`document.exports.schema.type.shortName.${document.type}`) }} {{ document.documentNumber }}
      </el-table-column>
      <el-table-column v-slot="{ row: { product } }" :label="$t('openIssuesModal.table.order.label.sku')">
        {{ product && product.sku }}
      </el-table-column>
      <el-table-column v-slot="{ row: { product } }" :label="$t('openIssuesModal.table.order.label.product')">
        {{ product && product.name }}
      </el-table-column>
      <el-table-column v-slot="{ row: { terms } }" :label="$t('openIssuesModal.table.order.label.terms')">
        {{ formatTerms(terms) }}
      </el-table-column>
      <el-table-column
        v-slot="{
          row: {
            differences,
            terms,
            metadata: { order, tenantName, supplierName },
          },
        }"
        width="250"
        :label="$t('openIssuesModal.table.order.label.details')"
      >
        <div class="details">
          <i18n
            v-for="({ type, templates: { billed, ordered } }, detailsIndex) in getDetails(differences, terms)"
            :key="`${type}-${order.id}`"
            :path="`openIssuesModal.table.order.details.${tableName}.${type}`"
            :class="{ 'second-phrase': detailsIndex === 0 }"
          >
            <template #billed>
              <span v-for="(term, index) in billed" :key="`billed-term-${index}-${order.id}`">
                <span :class="{ 'fw-bold': term.bold }">
                  {{ term.text }}
                </span>

                <span v-if="index === 0 && index !== billed.length - 1">
                  {{ ' + ' }}
                </span>
              </span>
            </template>
            <template #ordered>
              <span v-for="(term, index) in ordered" :key="`ordered-term-${index}-${order.id}`">
                <span :class="{ 'fw-bold': term.bold }">
                  {{ term.text }}
                </span>

                <span v-if="index === 0 && index !== ordered.length - 1">
                  {{ ' + ' }}
                </span>
              </span>
            </template>
            <template #customer>
              {{ tenantName }}
            </template>
            <template #supplier>
              {{ supplierName }}
            </template>
          </i18n>
        </div>
      </el-table-column>
      <el-table-column
        v-slot="{ row: { totalDiff } }"
        width="80"
        :label="$t('openIssuesModal.table.order.label.totalDiff')"
      >
        {{ formatMoneyShekels(totalDiff) || $t('commons.unknown') }}
      </el-table-column>
      <el-table-column v-slot="{ row: { metadata } }" width="150" class-name="action-column">
        <Button type="warning" class="order-button" @click="$emit('solve-diff', metadata)">
          {{ $t('commons.resolveDifference') }}
        </Button>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { Button } from '@/modules/core/components';

import {
  formatDate,
  formatMoneyShekels,
  formatPercent,
  formatQuantity,
  formatTerms,
  getDetails,
} from '../tools/formatters';

export default {
  components: { Button },

  props: {
    title: { type: String, required: true },
    records: { type: Array, required: true },
    tableName: { type: String, required: true },
  },
  methods: {
    formatDate,
    formatMoneyShekels,
    formatPercent,
    formatQuantity,
    formatTerms,
    getDetails,
  },
};
</script>

<style lang="scss" scoped>
@import './style';

.order {
  background: $background;

  tr {
    .order-button {
      visibility: hidden;
    }

    &:hover .order-button {
      visibility: visible;
    }
    .details {
      display: flex;
      flex-direction: column;
    }
  }

  ::v-deep .action-column {
    .cell {
      text-overflow: initial;
    }
  }

  @extend .selection-column;
}
</style>
