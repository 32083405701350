var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order"},[_c('h3',{staticClass:"open-issues-table-title"},[_vm._v(_vm._s(_vm.title))]),_c('el-table',{attrs:{"data":_vm.records},on:{"selection-change":function($event){return _vm.$emit('selection-change', $event)}}},[_c('el-table-column',{attrs:{"type":"selection","class-name":"selection-column"}}),_c('el-table-column',{attrs:{"width":"90","label":_vm.$t('openIssuesModal.table.order.label.date')},scopedSlots:_vm._u([{key:"default",fn:function({ row: { date } }){return [_vm._v(" "+_vm._s(_vm.formatDate(date))+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('openIssuesModal.table.order.label.order')},scopedSlots:_vm._u([{key:"default",fn:function({ row: { document } }){return [_vm._v(" "+_vm._s(_vm.$t(`document.exports.schema.type.shortName.${document.type}`))+" "+_vm._s(document.documentNumber)+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('openIssuesModal.table.order.label.sku')},scopedSlots:_vm._u([{key:"default",fn:function({ row: { product } }){return [_vm._v(" "+_vm._s(product && product.sku)+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('openIssuesModal.table.order.label.product')},scopedSlots:_vm._u([{key:"default",fn:function({ row: { product } }){return [_vm._v(" "+_vm._s(product && product.name)+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('openIssuesModal.table.order.label.terms')},scopedSlots:_vm._u([{key:"default",fn:function({ row: { terms } }){return [_vm._v(" "+_vm._s(_vm.formatTerms(terms))+" ")]}}])}),_c('el-table-column',{attrs:{"width":"250","label":_vm.$t('openIssuesModal.table.order.label.details')},scopedSlots:_vm._u([{key:"default",fn:function({
        row: {
          differences,
          terms,
          metadata: { order, tenantName, supplierName },
        },
      }){return [_c('div',{staticClass:"details"},_vm._l((_vm.getDetails(differences, terms)),function({ type, templates: { billed, ordered } },detailsIndex){return _c('i18n',{key:`${type}-${order.id}`,class:{ 'second-phrase': detailsIndex === 0 },attrs:{"path":`openIssuesModal.table.order.details.${_vm.tableName}.${type}`},scopedSlots:_vm._u([{key:"billed",fn:function(){return _vm._l((billed),function(term,index){return _c('span',{key:`billed-term-${index}-${order.id}`},[_c('span',{class:{ 'fw-bold': term.bold }},[_vm._v(" "+_vm._s(term.text)+" ")]),(index === 0 && index !== billed.length - 1)?_c('span',[_vm._v(" "+_vm._s(' + ')+" ")]):_vm._e()])})},proxy:true},{key:"ordered",fn:function(){return _vm._l((ordered),function(term,index){return _c('span',{key:`ordered-term-${index}-${order.id}`},[_c('span',{class:{ 'fw-bold': term.bold }},[_vm._v(" "+_vm._s(term.text)+" ")]),(index === 0 && index !== ordered.length - 1)?_c('span',[_vm._v(" "+_vm._s(' + ')+" ")]):_vm._e()])})},proxy:true},{key:"customer",fn:function(){return [_vm._v(" "+_vm._s(tenantName)+" ")]},proxy:true},{key:"supplier",fn:function(){return [_vm._v(" "+_vm._s(supplierName)+" ")]},proxy:true}],null,true)})}),1)]}}])}),_c('el-table-column',{attrs:{"width":"80","label":_vm.$t('openIssuesModal.table.order.label.totalDiff')},scopedSlots:_vm._u([{key:"default",fn:function({ row: { totalDiff } }){return [_vm._v(" "+_vm._s(_vm.formatMoneyShekels(totalDiff) || _vm.$t('commons.unknown'))+" ")]}}])}),_c('el-table-column',{attrs:{"width":"150","class-name":"action-column"},scopedSlots:_vm._u([{key:"default",fn:function({ row: { metadata } }){return [_c('Button',{staticClass:"order-button",attrs:{"type":"warning"},on:{"click":function($event){return _vm.$emit('solve-diff', metadata)}}},[_vm._v(" "+_vm._s(_vm.$t('commons.resolveDifference'))+" ")])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }