import { has } from 'ramda';

import i18n from '@/imports/startup/client/i18n';

import { formatDate, formatDateMonth, formatTerms, getDetails } from './formatters';
import { isOneMonth } from './utils';

let formatMoney, formatMoneyShekels;

const formatPdfDate = (date) => {
  return formatDate(date) || i18n.t('commons.unknownDate');
};

const formatPdfDocument = ({ type, documentNumber }) => {
  return [i18n.t(`document.exports.schema.type.shortName.${type}`), documentNumber].join(' ');
};

const createMissingDocumentItem = ({ date, document }) => {
  return {
    date: formatPdfDate(date),
    document: formatPdfDocument(document),
  };
};

const createMissingDocumentTable = (missingDocuments = []) => {
  return {
    title: i18n.t('openIssuesModal.table.missingDocuments.title'),
    items: missingDocuments.map(createMissingDocumentItem),
  };
};

const getText = (termsArray) => {
  return termsArray.map(({ text }) => text).join(' + ');
};

const createOrderItem = (orderTableItem, tableName) => {
  const {
    date,
    document,
    product,
    terms,
    totalDiff,
    differences,
    metadata: { tenantName, supplierName },
  } = orderTableItem;

  return {
    date: formatPdfDate(date),
    document: formatPdfDocument(document),
    sku: product?.sku,
    product: product?.name,
    terms: formatTerms(terms),
    differences: getDetails(differences, terms).map(({ type, templates: { billed, ordered } }) => {
      switch (type) {
        case 'amount':
          return {
            text: i18n.t(`openIssuesModal.table.order.details.${tableName}.${type}`, {
              billed: getText(billed),
              ordered: getText(ordered),
              supplier: supplierName,
              customer: tenantName,
            }),
          };
        case 'quantity':
          return {
            text: i18n.t(`openIssuesModal.table.order.details.${tableName}.${type}`, {
              billed: getText(billed),
              ordered: getText(ordered),
              supplier: supplierName,
              customer: tenantName,
            }),
          };
        default:
          return null;
      }
    }),
    totalDiff: formatMoneyShekels(totalDiff) || i18n.t('commons.unknown'),
  };
};

const createUnbilledTable = (unbilled) => {
  return {
    title: i18n.t('openIssuesModal.table.unbilled.title'),
    items: unbilled.map((unbilled) => createOrderItem(unbilled, 'unbilled')),
  };
};

const cerateDifferencesTable = ({ selected, metadata: { date, document, imbalance, isMissingPrice } }) => {
  const differencesTable = {
    title: i18n.t('openIssuesModal.table.title', {
      date: formatDate(date),
      documentType: i18n.t(`document.exports.schema.type.shortName.${document.type}`),
      documentNumber: document.documentNumber,
    }),
    items: selected.map((difference) => createOrderItem(difference, 'differences')),
  };

  if (!isMissingPrice && imbalance) {
    differencesTable.imbalance = i18n.t('openIssuesModal.table.imbalance.summary', {
      orderedAmount: formatMoney(imbalance.totalOrderedAmount),
      billedAmount: formatMoney(imbalance.totalBilledAmount),
    });

    differencesTable.total_imbalance = i18n.t('openIssuesModal.table.imbalance.totalDifference', {
      totalDiff: formatMoney(imbalance.totalDiffAmount),
    });
  }

  return differencesTable;
};

const createReport = ({ groupDate, unbilled, differences, missingDocuments }) => {
  const tables = {};

  if (differences) {
    tables.differences = {
      title: i18n.t('openIssuesModal.table.difference.title'),
      billings: Object.values(differences).map(cerateDifferencesTable),
    };
  }

  if (unbilled) {
    tables.unbilled = createUnbilledTable(unbilled);
  }

  if (missingDocuments) {
    tables.missingDocuments = createMissingDocumentTable(missingDocuments);
  }

  return {
    group_date: formatDateMonth(groupDate),
    tables,
  };
};

export const convertToPdfJson = (
  tenantName,
  supplierName,
  selectedRecords,
  range,
  formatToCurrency,
  formatCentsToCurrency
) => {
  formatMoney = formatCentsToCurrency;
  formatMoneyShekels = formatToCurrency;
  const reports = Object.values(selectedRecords);
  const hasDifferences = reports.some((report) => has('differences')(report) || has('unbilled')(report));
  const hasMissingDocuments = reports.some((report) => has('missingDocuments')(report));

  const oneMonth = isOneMonth(range);
  const subtitle = [
    i18n.t('openIssuesModal.pdf.selectedMonth.from', {
      variant: i18n.tc('openIssuesModal.pdf.month', oneMonth),
      from: formatDateMonth(range.from),
    }),
  ];
  if (!oneMonth) {
    subtitle.push(i18n.t('openIssuesModal.pdf.selectedMonth.to', { to: formatDateMonth(range.to) }));
  }
  return {
    from: tenantName,
    to: supplierName,
    reportName: `request_${new Date().toISOString().split('T')[0]}`,
    request_date: new Date().toLocaleDateString(i18n.locale),
    page_header:
      hasDifferences && hasMissingDocuments
        ? i18n.t('openIssuesModal.pdf.missingAndDifferences')
        : hasDifferences
        ? i18n.t('openIssuesModal.pdf.differences')
        : i18n.t('openIssuesModal.pdf.missingDocuments'),
    page_subtitle: subtitle.join(' '),
    reports: reports.map(createReport),
  };
};
