import { isNil } from 'ramda';
import i18n from '@/imports/startup/client/i18n';
import { currency, percent } from '@/locale/numberConfig';
import { options } from '@/locale/dateConfig';
import { TERM_TYPE } from './constants';

export const formatDate = (ms) => {
  return ms ? new Date(ms).toLocaleDateString(i18n.locale, options.short) : i18n.t('commons.unknownDate');
};
export const formatDateMonth = (ms) => {
  return ms ? new Date(ms).toLocaleString(i18n.locale, { month: 'long', year: 'numeric' }) : '';
};

export const formatMoney = (value) => {
  return typeof value === 'number' && !Number.isNaN(value)
    ? Number(value / 100).toLocaleString(i18n.locale, currency)
    : null;
};

export const formatMoneyShekels = (value) => {
  return typeof value === 'number' && !Number.isNaN(value) ? Number(value).toLocaleString(i18n.locale, currency) : null;
};

export const formatPercent = (number) => {
  return typeof number === 'number' && !Number.isNaN(number)
    ? Number(number / 100).toLocaleString(i18n.locale, percent)
    : null;
};

export const formatQuantity = (number) => {
  return typeof number === 'number' && !Number.isNaN(number) ? Number(number).toLocaleString(i18n.locale) : null;
};

export const formatTerms = ({ price, quantity, discount }) => {
  const terms = {};
  const texts = [];
  if (!isNil(price)) {
    terms.price = formatMoneyShekels(price);
  } else {
    terms.price = i18n.t('commons.unknown');
  }
  if (!isNil(discount)) {
    terms.discount = formatPercent(discount);
  }
  if (!isNil(quantity)) {
    terms.quantity = formatQuantity(quantity);
  }

  if (terms.quantity) {
    texts.push(terms.quantity);
  }
  if (terms.discount) {
    texts.push(`(${terms.price} + ${terms.discount})`);
  } else {
    texts.push(terms.price);
  }

  // do not change me to an english x - it messing all the text direction
  return texts.join(' ✕ ');
};

const formatByType = (type, value) => {
  switch (type) {
    case TERM_TYPE.QUANTITY:
      return formatQuantity(value);
    case TERM_TYPE.DISCOUNT:
      return formatPercent(value);
    case TERM_TYPE.PRICE:
      return formatMoneyShekels(value);
    default:
      return value;
  }
};

const addTerm = (termKey, businessKey, differences, terms) => {
  const diff = differences[termKey];
  return {
    type: termKey,
    text: diff ? formatByType(termKey, diff[businessKey]) : formatByType(termKey, terms[termKey]),
    bold: !!diff,
  };
};

const createTermsFactory = (differences, terms) => (termsKeys, businessKey) => {
  return termsKeys.map((key) => addTerm(key, businessKey, differences, terms)).filter((term) => term?.text);
};

export const getDetails = (differences, terms) => {
  const price = differences[TERM_TYPE.PRICE];
  const discount = differences[TERM_TYPE.DISCOUNT];
  const quantity = differences[TERM_TYPE.QUANTITY];

  const createTerms = createTermsFactory(differences, terms);
  const diffsToDisplay = [];

  if (price || discount) {
    diffsToDisplay.push({
      type: 'amount',
      templates: {
        billed: createTerms([TERM_TYPE.PRICE, TERM_TYPE.DISCOUNT], 'supplierValue'),
        ordered: createTerms([TERM_TYPE.PRICE, TERM_TYPE.DISCOUNT], 'customerValue'),
      },
    });
  }

  if (quantity) {
    diffsToDisplay.push({
      type: 'quantity',
      templates: {
        billed: createTerms([TERM_TYPE.QUANTITY], 'customerValue'),
        ordered: createTerms([TERM_TYPE.QUANTITY], 'supplierValue'),
      },
    });
  }
  return diffsToDisplay;
};
