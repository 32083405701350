<template>
  <el-dialog visible top="2%" append-to-body custom-class="issues-modal" @close="$emit('close')">
    <template #title>
      <div class="open-issues-header">
        <div class="open-issues-title">
          <span class="fw-bold">
            {{ $t('openIssuesModal.title') }}
          </span>
          <el-dropdown
            class="d-flex"
            trigger="click"
            :placement="$t('direction') === 'rtl' ? 'bottom-start' : 'bottom-end'"
            @command="(method) => _self[method]()"
          >
            <Button type="text" class="p-0 mb-2 text-typography-primary">
              <KebabIcon width="16px" height="16px" />
            </Button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :disabled="!Object.keys(selectedRecords).length" command="exportToPdf">
                {{ $t('openIssuesModal.moreActions.exportToPdf') }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div>
          <p class="supplier">
            {{ $t('openIssuesModal.subtitle', { supplierName }) }}
            {{ $tc('openIssuesModal.subtitleDate', isOneMonth) }}
          </p>
          <span class="fw-bold">
            {{ formatDateMonth(range.from) }}
          </span>
          <span v-if="!isOneMonth">
            {{ $t('openIssuesModal.until') }}

            <span class="fw-bold">
              {{ formatDateMonth(range.to) }}
            </span>
          </span>
        </div>
      </div>
    </template>
    <div class="reports">
      <template v-if="monthGroups.length">
        <div
          v-for="{ key, groupDate, reports, missingDocuments, unbilled } in monthGroups"
          :key="key"
          class="month-section"
        >
          <div class="month-badge bg-typography-primary">{{ formatDateMonth(groupDate) }}</div>
          <div v-for="{ date, document, differences, imbalance, isMissingPrice, id } in reports" :key="id">
            <div class="p-4" />
            <div class="imbalance">
              <h2 v-if="document">
                {{
                  $t('openIssuesModal.table.title', {
                    date: formatDate(date),
                    documentType: $t(`document.exports.schema.type.shortName.${document.type}`),
                    documentNumber: document.documentNumber,
                  })
                }}
              </h2>
              <div v-if="!isMissingPrice && imbalance">
                {{
                  $t('openIssuesModal.table.imbalance.summary', {
                    orderedAmount: formatToCurrency(imbalance.totalOrderedAmount),
                    billedAmount: formatToCurrency(imbalance.totalBilledAmount),
                  })
                }}
                <span class="fw-bold">
                  {{
                    $t('openIssuesModal.table.imbalance.totalDifference', {
                      totalDiff: formatToCurrency(imbalance.totalDiffAmount),
                    })
                  }}
                </span>
              </div>
            </div>
            <OrderTable
              v-if="differences.length"
              :records="differences"
              :title="$t('openIssuesModal.table.difference.title')"
              table-name="differences"
              @solve-diff="diffRowMetadata = $event"
              @selection-change="
                handleSelection(groupDate, 'differences', $event, {
                  recordId: id,
                  date,
                  document,
                  imbalance,
                  isMissingPrice,
                })
              "
            />
          </div>
          <div v-if="unbilled.length" class="p-4" />
          <OrderTable
            v-if="unbilled.length"
            :records="unbilled"
            :title="$t('openIssuesModal.table.unbilled.title')"
            table-name="unbilled"
            @solve-diff="diffRowMetadata = $event"
            @selection-change="handleSelection(groupDate, 'unbilled', $event)"
          />
          <div v-if="missingDocuments.length" class="p-4" />
          <MissingDocumentsTable
            v-if="missingDocuments.length"
            :missing-documents="missingDocuments"
            @selection-change="handleSelection(groupDate, 'missingDocuments', $event)"
          />
          <OrderDifferenceModal
            v-if="diffRowMetadata.order"
            :product-index="diffRowMetadata.index"
            :order="diffRowMetadata.order"
            :after-submit="refetch"
            :business="business"
            @close="diffRowMetadata = {}"
          />
        </div>
      </template>
      <template v-else>
        <div class="no-issues">
          {{ $t('openIssuesModal.noIssues') }}
        </div>
      </template>
    </div>
  </el-dialog>
</template>

<script>
import FileSaver from 'file-saver';
import { ref } from 'vue';

import { KebabIcon } from '@/assets/icons';
import { Button } from '@/modules/core';
import { useCurrency } from '@/modules/core/compositions/money-currency';
import { useBusinessById } from '@/modules/eventMapModal/compositions';

import { TERM_TYPE } from './tools/constants';
import { formatDate, formatDateMonth } from './tools/formatters';
import {
  createDifferenceTableRows,
  createMissingTableRows,
  createUnbilledTableRows,
  groupByMonth,
  sumArrayByKey,
  isMissingPrice,
  isOneMonth,
  modifySelectedRecords,
} from './tools/utils';
import { convertToPdfJson } from './tools/pdf';

import { OrderTable, MissingDocumentsTable } from './components';
import { PDF_GENERATE_MUTATION } from '../../compositions/graphql';

export default {
  components: {
    Button,
    KebabIcon,
    OrderTable,
    MissingDocumentsTable,
    OrderDifferenceModal: () => import('@/modules/eventMapModal/components/order/components/OrderDifferenceModal'),
  },
  props: {
    range: { type: Object, required: true },
    supplierName: { type: String, required: true },
    billings: { type: Array, required: true },
    orders: { type: Array, required: true },
    refetch: { type: Function, required: true },
    businessId: { type: String, required: true },
  },
  setup(props) {
    const { business } = useBusinessById(props.businessId);
    const { formatToCurrency, formatCentsToCurrency } = useCurrency();

    return {
      formatToCurrency,
      formatCentsToCurrency,
      business,
      TERM_TYPE,
      diffRowMetadata: ref({}),
      selectedRecords: ref({}),
    };
  },
  computed: {
    monthGroups() {
      const reports = this.billings
        .filter((b) => b.netAmount > 0)
        .map((billing) => {
          const totalOrderedAmount = sumArrayByKey(billing.imbalances, 'orderedAmount');
          const totalBilledAmount = sumArrayByKey(billing.imbalances, 'billedAmount');
          const differences = createDifferenceTableRows(billing, this.orders, this.business?.name, this.supplierName);

          const imbalance =
            totalBilledAmount && totalOrderedAmount
              ? {
                  totalDiffAmount: totalBilledAmount - totalOrderedAmount,
                  totalOrderedAmount,
                  totalBilledAmount,
                }
              : null;

          return differences.length || imbalance
            ? {
                id: billing.id,
                date: billing.date,
                imbalance,
                differences,
                document: billing.source?.document,
                isMissingPrice: isMissingPrice(this.orders),
              }
            : null;
        })
        .filter((report) => !!report)
        .sort((a, b) => a.date - b.date);

      const unbilledOrders = createUnbilledTableRows(
        this.billings,
        this.orders,
        this.business?.name,
        this.supplierName
      );
      const missingDocuments = createMissingTableRows(this.billings);

      return groupByMonth(reports, unbilledOrders, missingDocuments);
    },
    isOneMonth() {
      return isOneMonth(this.range);
    },
  },
  methods: {
    formatDate,
    formatDateMonth,
    handleSelection(groupDate, tableName, selected, recordId) {
      this.selectedRecords = modifySelectedRecords(groupDate, tableName, selected, this.selectedRecords, recordId);
    },
    async exportToPdf() {
      const loader = this.$loading();
      try {
        const json = convertToPdfJson(
          this.business?.name,
          this.supplierName,
          this.selectedRecords,
          this.range,
          this.formatToCurrency,
          this.formatCentsToCurrency
        );

        const {
          data: {
            pdfGenerate: { url },
          },
        } = await this.$apollo.mutate({
          mutation: PDF_GENERATE_MUTATION,
          variables: { template: 'activityIssues', data: json },
        });
        const response = await fetch(url);
        const blob = await response.blob();
        FileSaver.saveAs(blob, `${json.reportName}.pdf`);
        this.$message.success(this.$t('commons.messages.action.success'));
      } catch (error) {
        console.error(error); //TODO: add to logger when UI will have one
        this.$message.error(this.$t('commons.messages.action.error'));
      } finally {
        loader.close();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

$padding: 15px;
$border-radius: 6px;
$modal-height: 93vh;
$modal-padding-bottom: 20px;
$modal-header-height: 5.5em;

.open-issues-header {
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  padding: $padding;
  height: $modal-header-height;
  border-bottom: 1px solid $outline;

  .open-issues-title {
    line-height: 1.7em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    [dir='rtl'] & {
      padding-left: 30px;
    }
    [dir='ltr'] & {
      padding-right: 30px;
    }
    font-size: 1.5em;
  }

  .supplier {
    display: inline-block;
  }
}

.reports {
  background: $light-gray;
  height: calc(#{$modal-height} - #{$modal-header-height} - #{$modal-padding-bottom});
  overflow-y: scroll;

  .month-section {
    color: $typography-primary;

    .month-badge {
      $badge-padding: 5px;
      height: 2.5em;
      width: 7.3em;

      padding: $badge-padding;
      color: $white;
      font-size: 0.8572em;
      font-weight: 400;
      line-height: 104%;
      display: flex;
      align-items: center;
      margin-top: 30px;
      justify-content: flex-end;

      [dir='rtl'] & {
        border-radius: 6px 0px 0px 6px;
        padding-left: calc(#{$badge-padding} + 4px);
        margin-right: -1px;
      }
      [dir='ltr'] & {
        width: 8em;
        border-radius: 0px 6px 6px 0px;
        padding-right: calc(#{$badge-padding} + 2px);
      }
    }
    .imbalance {
      background: $white;
      padding: 17px 23px;
      border-top: 1px solid $outline;
      border-bottom: 1px solid $outline;
    }
  }
}

.no-issues {
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 15vh;
  color: $typography-primary;
  font-size: 2em;
}

::v-deep .issues-modal {
  color: $typography-primary;
  border-radius: $border-radius;
  height: 93vh;
  width: 85vw;

  margin-bottom: 0;
  padding-bottom: $modal-padding-bottom;

  .el-dialog__header,
  .el-dialog__body {
    padding: 0;
  }
}
</style>
