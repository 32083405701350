export const TERM_TYPE = {
  QUANTITY: 'quantity',
  DISCOUNT: 'discount',
  PRICE: 'price',
};

export const TABLE_TYPE = {
  DIFFERENCES: 'differences',
  UNBILLED: 'unbilled',
  MISSING_DOCUMENTS: 'missingDocuments',
};
