<template>
  <div class="missing-documents">
    <h3 class="open-issues-table-title">{{ $t('openIssuesModal.table.missingDocuments.title') }}</h3>
    <el-table :data="missingDocuments" @selection-change="$emit('selection-change', $event)">
      <el-table-column type="selection" class-name="selection-column" />
      <el-table-column
        v-slot="{ row: { date } }"
        :label="$t('openIssuesModal.table.missingDocuments.label.date')"
        width="120px"
      >
        {{ formatDate(date) || $t('commons.unknownDate') }}
      </el-table-column>
      <el-table-column
        v-slot="{
          row: {
            document: { documentNumber, type },
          },
        }"
        :label="$t('openIssuesModal.table.missingDocuments.label.document')"
      >
        {{ $t(`document.exports.schema.type.shortName.${type}`) }} {{ documentNumber }}
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { formatDate } from '../tools/formatters';

export default {
  props: {
    missingDocuments: { type: Array, default: () => [] },
  },
  methods: {
    formatDate,
  },
};
</script>

<style lang="scss" scoped>
@import './style';

.missing-documents {
  background: $background;

  @extend .selection-column;
}
</style>
